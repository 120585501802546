import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

import { initializeApp } from "firebase/app";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC7ObTAvhU3K6jhtRuMKVzr0wkfGajXHPo",
  authDomain: "kaffeemanufaktur-2022.firebaseapp.com",
  databaseURL: "https://kaffeemanufaktur-2022-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "kaffeemanufaktur-2022",
  storageBucket: "kaffeemanufaktur-2022.appspot.com",
  messagingSenderId: "152157171977",
  appId: "1:152157171977:web:0ac24473b7adfd874363d8"
};

const fb = initializeApp(firebaseConfig);

const app = createApp(App)
  .use(IonicVue)
  .use(router);
  
router.isReady().then(() => {
  app.mount('#app');
});