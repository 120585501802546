
import KaffeeReiheVue from '@/components/KaffeeReihe.vue';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonGrid, IonFab, IonFabButton, IonIcon } from '@ionic/vue';
import { refresh } from 'ionicons/icons';
import { defineComponent } from 'vue';

import { getDatabase, ref, child, get } from "firebase/database";

interface KaffeeData {
  name: string,
  lager: boolean,
  eimer: Array<number>
}

export default defineComponent({
  name: 'HomePage',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonGrid,
    //IonButtons,
    //IonBackButton,
    IonFab,
    IonFabButton,
    IonIcon,
    "kaffee-reihe": KaffeeReiheVue,
  },
  data(){
    return {
      lager: Array<KaffeeData>(),
    };
  },
  created(){
    this.updateData();
    setInterval(()=>this.updateData(), 30000);
    
  },
  setup() {
    return {
      refresh,
    }
  }, 
  methods:{
    eimerChanged: function(val:any){
      console.log("event arrived:");
      console.log(val);

      this.lager[val.kaffeeId].eimer[val.eimerNo] = val.value;
      //this.lager[val.kaffeeId][val.eimerNo] = val.value;

      /*if(this.lager[val.kaffee]){

      }*/
    }, 
    updateData: function(){
      const dbRef = ref(getDatabase());
      get(child(dbRef, 'manufaktur')).then((snapshot) => {
        if (snapshot.exists()) {
          console.log(snapshot.val());

          if(snapshot.val().lager){
            this.lager = snapshot.val().lager;
            localStorage.lager = snapshot.val().lager;
          }
        } else {
          console.log("No data available");
        }
      }).catch((error) => {
        console.error(error);
      });
    }
  },
});
