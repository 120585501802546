import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a1f7111a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "kaffeename" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!

  return (_ctx.visible)
    ? (_openBlock(), _createBlock(_component_ion_row, { key: 0 }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_col, { size: "6" }, {
            default: _withCtx(() => [
              _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.name), 1)
            ]),
            _: 1
          }),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.eimer, (val, index) => {
            return (_openBlock(), _createBlock(_component_ion_col, {
              size: "1",
              class: _normalizeClass(["ion-align-self-start", {"eimer-leer": !val, "eimer-voll": val}]),
              onClick: ($event: any) => (_ctx.toggleState(index)),
              key: "eimer_" + _ctx.name + "_" + val,
              style: {"text-align":"center"}
            }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, _toDisplayString(index + 1), 1)
              ]),
              _: 2
            }, 1032, ["onClick", "class"]))
          }), 128))
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}