
import { IonCol, IonRow } from '@ionic/vue';
import { defineComponent } from 'vue';

import { getDatabase, ref, set } from "firebase/database";

export default defineComponent({
  name: 'KaffeeReihe',
  props: {
    name: String,
    eimer: Array,
    visible: Boolean,
    id: Number,
    admin: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    IonCol,
    IonRow,
  },
  methods: {
    toggleState: function(no:number){
      
      console.log("EimerNummer: " + no);
      if(this.admin){
        if(this.eimer){
          console.log("Set EimerNummer: " + no);
          let wert:any = this.eimer[no];
          wert = (wert  + 1)%2;
          const db = getDatabase();
          set(ref(db, `manufaktur/lager/${this.id}/eimer/${no}/`), wert)
          .then(() => {
            console.log("success");
            let data: any = {};
            data.kaffeeId = this.id;
            data.eimerNo = no;
            data.value = wert;
            this.$emit("eimer-changed", data);
          })
          .catch((error) => {
            console.log("coudn't wirte date: " + error );
          });
        }
      } else {
        console.log("no rights to set state");
      }
    } 
  }
});
